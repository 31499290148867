import React from "react";
import { Button, IconButton } from "@material-ui/core";

import { styled } from "@material-ui/core/styles";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const WhiteButton = styled(Button)({
  color: "#fff",
  fontSize: 11,
});

const MobileSectionTitle = ({
  title,
  buttonTitle = null,
  onButtonClick = () => {},
  containerStyle = {},
  collapse = null,
  setCollapse = null,
  id=null
}) => {
  let collapseButton = null;

  if (collapse !== null) {
    collapseButton = (
      <IconButton
        style={{ color: "white" }}
        aria-label="expand row"
        size="large"
        onClick={() => setCollapse(!collapse)}
      >
        {collapse === false ? (
          <KeyboardArrowUpIcon />
        ) : (
          <KeyboardArrowDownIcon />
        )}
      </IconButton>
    );
  }

  return (
    <div className={`section-title-mobile ${collapse ? "section-title-mobile__open" : ""}`} id={id}>
      <span>
        {(title !== "RECALLS" && title !== "NOTES") ? `${title} CONDITION` : title}
      </span>
      {buttonTitle && (
        <WhiteButton
          color="primary"
          onClick={onButtonClick}
          style={{ marginTop: "3px" }}
        >
          {buttonTitle}
        </WhiteButton>
      )}
      {collapseButton}
    </div>
  );
};

export default MobileSectionTitle;
