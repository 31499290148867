import React from "react";

export const _getCategoryCondition = ({ category, data }) => {
  let listOfDamages = "";

  if (
    category.name === "EXTERIOR" ||
    category.name === "INTERIOR" ||
    category.name === "ELECTRICAL" ||
    category.name === "FRAME"
  ) {
    category.subcategories.map((subcategory) => {
      subcategory.steps.map((step, index) => {
        let damage =
          step.damage === "OK" || step.damage === "None" ? "" : step.damage;

        if (damage?.length > 0) {
          listOfDamages = `${listOfDamages} ${step.name} (${damage}),`;
        }
      });
    });
  }

  if (listOfDamages?.length > 0) {
    listOfDamages = `${listOfDamages.substring(0, listOfDamages.length - 1)}.`;
  }

  if (category.name === "EXTERIOR") {
    let conditionFirst = (
      <>
        We have inspected the exterior of the{" "}
        {<span className="condition-text">{data.carModel}</span>}. Based on the
        current mileage of{" "}
        {<span className="condition-text">{data.mileage}</span>}, we evaluate
        the condition of the vehicle as{" "}
        {category?.condition ? category?.condition?.toUpperCase() : "EXCELLENT"}
        .
      </>
    );

    let conditionSecond = (
      <>
        We have inspected the exterior of the{" "}
        {<span className="condition-text">{data.carModel}</span>}. Based on the
        current mileage of{" "}
        {<span className="condition-text">{data.mileage}</span>}, this vehicle
        has {category?.condition?.toUpperCase()}.
      </>
    );

    if (category.condition === "excellent" || !category.condition) {
      return (
        <>
          {conditionFirst} During the inspection we did not find any outstanding
          issues with the exterior of the vehicle.
        </>
      );
    } else if (
      category.condition !== "excellent" &&
      listOfDamages?.length < 1
    ) {
      if (category.condition === "better than average") {
        return <>{conditionFirst}</>;
      } else if (
        category.condition === "normal wear and tear" ||
        category.condition === "excessive wear and tear"
      ) {
        return <>{conditionSecond}</>;
      }
    } else if (
      category.condition !== "excellent" &&
      listOfDamages?.length > 0
    ) {
      if (category.condition === "better than average") {
        return (
          <>
            {conditionFirst} During the inspection, we have found{" "}
            {listOfDamages}
          </>
        );
      } else if (
        category.condition === "normal wear and tear" ||
        category.condition === "excessive wear and tear"
      ) {
        return (
          <>
            {conditionSecond} During the inspection, we have found{" "}
            {listOfDamages}
          </>
        );
      }
    }
  } else if (category.name === "INTERIOR") {
    let conditionValue = category.condition
      ? category.condition
      : "was kept in excellent condition and was well taken care of by the owner";

    if (category.condition === "excellent") {
      conditionValue =
        "was kept in excellent condition and was well taken care of by the owner";
    }

    const condition = (
      <>
        The{" "}
        {
          <span className="condition-text">
            {data.interiorColor} {data.interiorMaterial}
          </span>
        }{" "}
        interior of the{" "}
        {<span className="condition-text">{data.carModel}</span>}{" "}
        {conditionValue}.
      </>
    );

    if (category.condition === "excellent" || !category.condition) {
      return (
        <>
          {condition} During the inspection, we did not find any outstanding
          issues with the interior of the vehicle.
        </>
      );
    } else if (
      category.condition !== "excellent" &&
      listOfDamages?.length < 1
    ) {
      return <>{condition}</>;
    } else if (
      category.condition !== "excellent" &&
      listOfDamages?.length > 0
    ) {
      return (
        <>
          {condition} During the inspection, we have found {listOfDamages}
        </>
      );
    }
  } else if (category.name === "MECHANICAL") {
    let conditionValue = "";

    if (category.condition === "excellent" || !category.condition) {
      conditionValue =
        "After inspection, we found that these parameters are in excellent condition.";
    } else if (category.condition === "maintance due (no proof of maintance)") {
      conditionValue =
        "After inspection of the stated parameters, we found that maintence is needed.";
    } else if (category.condition === "repairs required") {
      conditionValue =
        "After inspection of the stated parameters, we found that repaires are required.";
    } else if (category.condition === "not safe to operate") {
      conditionValue =
        "After inspection of the stated parameters, we found that the vehicle is not safe to operate.";
    }

    if (
      category.condition ===
      "unable to perform test drive during the inspection"
    ) {
      return "We were unable to perform a test drive during the inspection.";
    }

    return (
      <>
        The power comes from{" "}
        {
          <span className="condition-text">
            {data.liters}L {data.cylinders}
          </span>
        }{" "}
        Cylinders Engine with{" "}
        {<span className="condition-text">{data.transmissionType}</span>}{" "}
        transmission with{" "}
        {<span className="condition-text">{data.drivetrain}</span>}. We have
        checked the vehicle's engine oil, brake fluid, and timing belt.{" "}
        {conditionValue}
      </>
    );
  } else if (category.name === "ELECTRICAL") {
    const condition = (
      <>
        The electrical components of the{" "}
        {<span className="condition-text">{data.carModel}</span>} is fundamental
        to a vehicle's operation and safety.
      </>
    );
    let conditionValue =
      " During the inspection we did not find any outstanding issues with the electrical system of the vehicle.";

    if (category.condition === "excellent" || !category.condition) {
      return (
        <>
          {condition} {conditionValue}
        </>
      );
    } else if (
      category.condition !== "excellent" &&
      listOfDamages?.length < 1
    ) {
      if (category.condition === "some components INOP") {
        return <>{condition}</>;
      } else if (category.condition === "requires diagnostics and repair") {
        return (
          <>
            {condition} Upon completion of the inspection we are suggesting a
            complete vehicle diagnostics to have enough data for identification
            of the electric car issues.
          </>
        );
      }
    } else if (
      category.condition !== "excellent" &&
      listOfDamages?.length > 0
    ) {
      if (category.condition === "some components INOP") {
        return (
          <>
            {condition} During the inspection we have found {listOfDamages}
          </>
        );
      } else if (category.condition === "requires diagnostics and repair") {
        return (
          <>
            {condition} Upon completion of the inspection we are suggesting a
            complete vehicle diagnostics to have enough data for identification
            of the electric car issues. During the inspection we have found{" "}
            {listOfDamages}
          </>
        );
      }
    }
  } else if (category.name === "FRAME") {
    let carShows = "After checking all four frame railes, the car shows";
    if (category.condition === "no frame damage" || !category.condition) {
      return (
        <>
          During the inspection we have not found any issues with the frame. {carShows} no frame damage or previous frame repairs.
        </>
      );
    } else if (category.condition === "unable to check") {
      return (
        <>
          We were not able to perform inspection of the frame due to the splash
          shield covers.
        </>
      );
    } else if (
      (category.condition !== "no frame damage" ||
        category.condition !== "unable to check") &&
      listOfDamages?.length < 1
    ) {
      if (category.condition === "previous repairs") {
        return (
          <>
            {carShows} previous frame damage or repairs. Additional frame
            inspection is required.
          </>
        );
      } else if (category.condition === "frame damage") {
        return (
          <>
            {carShows} that the vehicle frame has damage. Frame repair is
            required.
          </>
        );
      }
    } else if (
      (category.condition !== "no frame damage" ||
        category.condition !== "unable to check") &&
      listOfDamages?.length > 0
    ) {
      if (category.condition === "previous repairs") {
        return (
          <>
            {carShows} previous frame damage or repairs. Additional frame
            inspection is required. During the inspection we have found{" "}
            {listOfDamages}
          </>
        );
      } else if (category.condition === "frame damage") {
        return (
          <>
            {carShows} that the vehicle frame has damage. Frame repair is
            required. During the inspection we have found {listOfDamages}
          </>
        );
      }
    }
  } else if (category.name === "TIRES") {
    const firstText = "We have inspected all tires on the car and found that ";
    const secondText = (
      <>
        The current tread of the left front tire is{" "}
        {<span className="condition-text">{data.tiresData.leftFront}"</span>},
        right front tire tread is{" "}
        {<span className="condition-text">{data.tiresData.rightFront}"</span>},
        left rear tire is{" "}
        {<span className="condition-text">{data.tiresData.leftRear}"</span>},
        right rear tire tread is{" "}
        {<span className="condition-text">{data.tiresData.rightRear}"</span>}.
      </>
    );
    const thirdText = (
      <>
        Please consider tire replacement when the tire tread is below{" "}
        {<span className="condition-text__critical">5/32"</span>}.
      </>
    );

    if (category.condition === "good condition" || !category.condition) {
      return (
        <>
          {firstText} the tires are in good condition. {secondText}
        </>
      );
    } else if (category.condition === "show sign of wear and tear") {
      return (
        <>
          {firstText} the tires show sign of wear and tear. {secondText}{" "}
          {thirdText}
        </>
      );
    } else if (
      category.condition === "some of the tires required replacement"
    ) {
      return (
        <>
          {firstText} some of the tires require replacement. {secondText}{" "}
          {thirdText}
        </>
      );
    } else if (category.condition === "all tires required replacement") {
      return (
        <>
          {firstText} all of the tires required replacement. {secondText}{" "}
          {thirdText}
        </>
      );
    }
  }

  return null;
};