const models = {
  Sedan: {
    body: require("./Sedan/body.jpg"),
    frame: require("./Sedan/frame.jpg"),
    interior: require("./Sedan/interior.jpg"),
    tires: require("./Sedan/tires.jpg")
  },
  Coupe: {
    body: require("./Coupe/body.jpg"),
    frame: require("./Coupe/frame.jpg"),
    interior: require("./Coupe/interior.jpg"),
    tires: require("./Coupe/tires.jpg")
  },
  SUV: {
    body: require("./SUV/body.jpg"),
    frame: require("./SUV/frame.jpg"),
    interior: require("./SUV/interior.jpg"),
    tires: require("./SUV/tires.jpg")
  },
  Hatchback: {
    body: require("./Hatchback/body.jpg"),
    frame: require("./Hatchback/frame.jpg"),
    interior: require("./Hatchback/interior.jpg"),
    tires: require("./Hatchback/tires.jpg")
  },
  Van: {
    body: require("./Van/body.jpg"),
    frame: require("./Van/frame.jpg"),
    interior: require("./Van/interior.jpg"),
    tires: require("./Van/tires.jpg")
  },
  Truck: {
    body: require("./Truck/body.jpg"),
    frame: require("./Truck/frame.jpg"),
    interior: require("./Truck/interior.jpg"),
    tires: require("./Truck/tires.jpg")
  }
};

export const modelSize = { w: 500, h: 456 };
export default models;
