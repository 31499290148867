import React from "react";
import { ReactComponent as VinIcon } from "../../icons/vin-scan.svg";
import { ReactComponent as CoinIcon } from "../../icons/dollar-icon.svg";
import { ReactComponent as NotDamaged } from "../../icons/ok.svg";
import { ReactComponent as Damaged } from "../../icons/cross.svg";
import IconOtherColor from "../../components/IconOtherColor";
import IconColor from "../../components/IconColor";
import { blackA, fullMonthsArray } from "../../../constants";
import moment from "moment";
import "./index.css";

const Feature = ({ text, damage }) => {
  return (
    <div className="main-block__feature-item">
      {text}

      {text.includes("Color") && !text.includes("Other") ? (
        <IconColor text={text} />
      ) : text.includes("Color") && text.includes("Other") ? (
        <IconOtherColor />
      ) : text.includes("Manual") && damage === "OK" ? (
        <NotDamaged className="main-block__damage" />
      ) : text.includes("Manual") && damage !== "OK" ? (
        <Damaged className="main-block__damage damaged" />
      ) : null}
    </div>
  );
};

function InfoBlock({
  attributes,
  vin,
  vehicle,
  pickedVehicleDetails,
  location,
  time,
  marketValues,
  manualDamage,
}) {
  const {
    mileage,
    interiorColor,
    exteriorColor,
    interiorMaterial,
    register_year,
    register_month,
  } = pickedVehicleDetails;
  const mileWord = mileage === 1 ? "mile" : "miles";

  const firstFeatures = [
    `${mileage} ${mileWord}`,
    attributes.drivetrain || "",
    attributes.engine_size ? `${attributes.engine_size} L` : "",
    attributes.standard_seating ? `${attributes.standard_seating} Seats` : "",
    attributes.engine_cylinders
      ? `${attributes.engine_cylinders} Cylinder Engine`
      : "",
    attributes.transmission_type
      ? `${attributes.transmission_type} Transmission`
      : "",
    `Manual`,
  ];

  const secondFeatures = [
    interiorMaterial ? `Interior: ${interiorMaterial}` : "",
    exteriorColor ? `Exterior Color: ${exteriorColor}` : "",
    interiorColor ? `Interior Color: ${interiorColor}` : "",
  ];

  const thirdFeatures = [
    register_year && register_month
      ? `Registration: ${fullMonthsArray[register_month - 1]} ${register_year}`
      : "",
    time
      ? `Inspection date: ${
          time && moment(time).format("DD MMMM YYYY")
        }, ${moment(time).format("hh:mm A")}`
      : "",
    location ? `Location: ${location}` : "",
  ];

  return (
    <div className="main-block__info">
      <div className="vin-block">
        {vin && (
          <>
            <span className="vin-item">VIN</span>
            <span className="vin-item">{vin.toUpperCase()}</span>
          </>
        )}
      </div>

      <div className="main-block__features-container">
        {firstFeatures
          .filter((f) => f)
          .map((e, index) => (
            <Feature
              text={e}
              key={`first-feature-${index}`}
              damage={manualDamage}
            />
          ))}
      </div>

      <div className="main-block__features-container">
        {secondFeatures
          .filter((f) => f)
          .map((e, index) => (
            <Feature text={e} key={`second-feature-${index}`} />
          ))}
      </div>
      <div className="main-block__features-container">
        {thirdFeatures
          .filter((f) => f)
          .map((e, index) => (
            <Feature text={e} key={`third-feature-${index}`} />
          ))}
      </div>

      {marketValues && (
        <h2 className="main-block__market-price">
          Market price ${parseInt(marketValues.below)} - $
          {parseInt(marketValues.above)}
        </h2>
      )}

      {/* <p>
      Estimates based on 913 similar vehicles sold between May 03, 2021 - Aug 14, 2021
      </p> */}

      {/* <div className="main-block__value-container">
            <CoinIcon className="coin-icon"/>
            <span className="caro-value">Caro value: $10221</span>
      </div> */}
    </div>
  );
}
export default InfoBlock;
