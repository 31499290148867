import React, { useState } from "react";
import { resizedPhotoUrl } from "../../helpers";

import "./index.css";

function FixedBlockWithCar({ attributes, image }) {
  const [newPosition, setNewPosition] = useState(false);

  const vehicleCustomer = `${attributes.year} ${attributes.make} 
  ${attributes.model}`;

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 5) {
      setNewPosition(true);
    } else if (scrolled <= 5) {
      setNewPosition(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div
      className={`fixed-block-with-car ${
        newPosition ? "new_position" : "current_position"
      }`}
    >
      <div className="fixed-image">
        <picture>
          <source
            srcset="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
            media="(min-width: 768px)"
          />
          <img src={resizedPhotoUrl(image, 333)} alt="car" height="70px" />
        </picture>
      </div>
      <div className="fixed-vehicle">{vehicleCustomer}</div>
    </div>
  );
}
export default FixedBlockWithCar;
