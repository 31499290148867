import {StyleSheet} from "@react-pdf/renderer"

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
    },
    detailBlock: {
        height: 70,
        width: 340,
        marginTop: 10,
        borderColor: '#eee',
        borderWidth: 2,
        padding: 20,
        borderRadius: 10,
    },
    detailTitle: {
        fontSize: 13,
    },
    detailValue: {
        fontSize: 17,
    },
});

export default styles;
