import React, { useEffect, useState } from "react";
import SectionTitle from "../../SectionTitle";
import "./index.css";
import Collapse from "@material-ui/core/Collapse";
import MobileSectionTitle from "../../MobileSectionTitle";

function FinalNotes({ notes }) {
  const [collapse, setCollapse] = useState(false);

  return (
    <div className="notes-container">
      <SectionTitle
        title="NOTES"
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <MobileSectionTitle
        title="NOTES"
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={!collapse} timeout="auto" unmountOnExit className="notes">
        {notes ? notes : "Inspector didn't leave notes."}
      </Collapse>
    </div>
  );
}
export default FinalNotes;
