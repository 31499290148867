import React from "react";
import {Text, View, Image} from "@react-pdf/renderer";
import styles from "./styles";

const StupidBlock = ({data}) => {
    const tableData = data.steps;

    const TableRow = ({row, half}) => {
        const {name, damage, severity, recommendation} = row;
        return (
            <View style={styles.bodySub}>
                <Text style={styles.text}>{`(${half + 1}) ${name}`}</Text>
                <Text style={styles.text}>
                    {damage !== "OK" ? (
                        damage
                    ) : (
                        <Image source={require("../../assets/choose.png")}/>
                    )}
                </Text>
                <Text style={styles.text}>{damage !== "OK" ? severity : ""}</Text>
                <Text style={styles.text}>{damage !== "OK" ? recommendation : ""}</Text>
            </View>
        );
    };

    return (
        <View wrap={false}>
            <Text style={styles.exterior}>{data.name.toUpperCase()}</Text>
            <View style={styles.containerForBorder}>
                <View style={styles.header}>
                    <View style={styles.headerRow}>
                        <Text style={styles.text}/>
                        <Text style={styles.text}>DAMAGE</Text>
                        <Text style={styles.text}>SEVERITY</Text>
                        <Text style={styles.text}>RECOMENDATION</Text>
                    </View>
                    <View style={styles.headerRow}>
                        <Text style={styles.text}></Text>
                        <Text style={styles.text}>DAMAGE</Text>
                        <Text style={styles.text}>SEVERITY</Text>
                        <Text style={styles.text}>RECOMENDATION</Text>
                    </View>
                </View>
                <View
                    style={{
                        display: "flex",
                        height: (tableData.length * 50) / 2,
                        flexWrap: "wrap",
                        width: "100%"
                    }}
                >
                    {tableData.map((e, index) => (
                        <TableRow row={e} key={index} half={index}/>
                    ))}
                </View>
            </View>
        </View>
    );
};

export default StupidBlock;
