import "typeface-roboto";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { fetchMultiplePhotos, useAppParams } from "./helpers";
import Loader from "react-loader-spinner";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import InspectionInfoDocument from "./components/InspectionInfoDocument";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import HTMLResult from "./html";
import { PDFViewer } from "@react-pdf/renderer";
import PDFDownload from "./components/PDFDownload";

import { makeStyles } from "@material-ui/styles";
import { formatDataPDF, formatDataHTML } from "./_formatters";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    background: "#3d3e49",
  },
  viewer: {
    width: "100%",
    height: "80vh",
  },
  heading: {
    marginLeft: 20,
    marginTop: 5,
  },
}));

const MainPage = () => {
  const [status, setStatus] = useState("loading");
  const [data, setData] = useState(null);
  const [finalPhotoPDF, setFinalPhotosPDF] = useState([]);

  const classes = useStyles();

  const { appointmentID, format, type } = useAppParams();

  useEffect(() => {
    document.title = `Caro Inspection | Condition report #${appointmentID}`
  }, [])

  useEffect(() => {
    async function fetchData() {
      setStatus("loading");
      let url;
      if (type === "customer") {
        url = `${process.env.REACT_APP_API_PATH}/report/appointments/${appointmentID}`;
      } else {
        url = `${process.env.REACT_APP_API_PATH}/report/inspector/appointments/${appointmentID}`;
      }

      try {
        const res = await fetch(url);

        const data = await res.json();

        let formattedData;
        if (format === "pdf") {
          formattedData = await formatDataPDF(data);
          const overviewPhotos = await fetchMultiplePhotos(
            data.inspection.overview_photos
          );
          setFinalPhotosPDF(overviewPhotos);
        } else {
          formattedData = await formatDataHTML(data);
        }
        setData(formattedData);
        setStatus("success");
      } catch (err) {
        setStatus("failed");
      }
    }

    fetchData();
  }, [appointmentID, type, format]);

  let content;

  if (format === "pdf" && data) {
    content = (
      <div
        style={{
          width: "80vw",
          height: "100vh",
          display: "flex",
          marginTop: 30,
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <PDFDownload
          data={data}
          appointmentID={appointmentID}
          type={type}
          overviewPhotos={finalPhotoPDF}
        />
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon style={{ color: "#eee" }} />}
            aria-controls="panel1a-content"
            style={{
              display: "flex",
            }}
            id="panel1a-header"
          >
            <Typography>View PDF</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <PDFViewer className={classes.viewer}>
              <InspectionInfoDocument
                data={data}
                overviewPhotos={finalPhotoPDF}
                categories={data.newCategories}
              />
            </PDFViewer>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  } else if (data) {
    content = <HTMLResult data={data} appointmentId={appointmentID} />;
  }

  return (
    <div className="app-container">
      {status === "success" && content}
      {status === "loading" && (
        <Loader type="BallTriangle" color="#9C9BD4" height={30} width={30} />
      )}
      {status === "failed" && <Typography>Something went wrong...</Typography>}
    </div>
  );
};

function App() {
  return (
    <Router>
      <Route exact path="/:appointmentID" component={MainPage} />
    </Router>
  );
}

export default App;
