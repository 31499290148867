import React, { useState } from "react";
import SectionTitle from "../../SectionTitle";
import Collapse from "@material-ui/core/Collapse";
import MobileSectionTitle from "../../MobileSectionTitle";
import "./index.css";

function Recalls({ recalls }) {
  const [collapse, setCollapse] = useState(true);

  return (
    <div className="recalls-container">
      <SectionTitle
        title="RECALLS"
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <MobileSectionTitle
        title="RECALLS"
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <Collapse in={!collapse} timeout="auto" unmountOnExit className="recalls-collapse">
        {recalls &&
          recalls.map((recall, index) => {
            return (
              <div key={`recall-${index}`}>
                <h3>
                  <b>{recall.date}: {recall.components}</b>
                </h3>
                <p>{recall.summary}</p>
              </div>
            );
          })}
      </Collapse>
    </div>
  );
}
export default Recalls;
