import React from "react";
import "./index.css";
import moment from "moment";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-copyright">
        Caro Automotive LLC © Copyright {moment().format("YYYY")}
      </div>

      <div className="footer-audit">
        <span>Price provided by </span>{" "}
        <a
          style={{ position: "relative", bottom: "3px" }}
          href="https://www.vinaudit.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("../icons/vin-audit.png")}
            style={{ width: "100px" }}
            alt="vin-audit"
          />
        </a>
      </div>

      <div className="footer-empty"></div>
    </div>
  );
}
export default Footer;
