import React from "react";
import "./index.css";
import Category from "./Category";
import MobileCategory from "./MobileCategory";
import TIRES_ICON from "../../icons/categories_/Tires.svg";
import FRAME_ICON from "../../icons/categories_/Frame.svg";
import EXTERIOR_ICON from "../../icons/categories_/Exterior.svg";
import INTERIOR_ICON from "../../icons/categories_/Interior.svg";
import MECHANICAL_ICON from "../../icons/categories_/Mechanical.svg";
import ELECTRICAL_ICON from "../../icons/categories_/Electrical.svg";

function Categories({ data }) {
  const categoriesPictures = [
    { title: "ELECTRICAL", src: ELECTRICAL_ICON },
    { title: "MECHANICAL", src: MECHANICAL_ICON },
    { title: "EXTERIOR", src: EXTERIOR_ICON },
    { title: "FRAME", src: FRAME_ICON },
    { title: "INTERIOR", src: INTERIOR_ICON },
    { title: "TIRES", src: TIRES_ICON },
  ];

  const getDataForTires = () => {
    let leftFront = "";
    let rightFront = "";
    let leftRear = "";
    let rightRear = "";

    data.categories.map((category) => {
      if (category.name === "TIRES") {
        category.subcategories.map((subcategory) => {
          if (subcategory.name === "L FRONT") {
            leftFront = subcategory?.steps[0]?.specialFactors[2]?.value ?? "";
          } else if (subcategory.name === "L REAR") {
            leftRear = subcategory?.steps[0]?.specialFactors[2]?.value ?? "";
          } else if (subcategory.name === "R FRONT") {
            rightFront = subcategory?.steps[0]?.specialFactors[2]?.value ?? "";
          } else if (subcategory.name === "R REAR") {
            rightRear = subcategory?.steps[0]?.specialFactors[2]?.value ?? "";
          }
        });
      }
    });

    return {
      leftFront,
      rightFront,
      leftRear,
      rightRear,
    };
  };

  const dataForCategory = {
    carModel: `${data.attributes.year} ${data.attributes.make} 
    ${data.attributes.model}`,
    mileage: data.pickedVehicleDetails.mileage,
    interiorColor: data.pickedVehicleDetails.interiorColor,
    interiorMaterial: data.pickedVehicleDetails.interiorMaterial,
    liters: data.attributes.engine_size,
    cylinders: data.attributes.engine_cylinders,
    transmissionType: data.attributes.transmission_type,
    drivetrain: data.attributes.drivetrain,
    tiresData: getDataForTires(),
  };

  return (
    <div className="categories-container">
      <span className="report-small-title report-details-small">Details</span>
      {data.categories.map((category, index) => {
        if (category.name !== "REQUIRED") {
          const picture = categoriesPictures.filter(
            (picture) => picture.title === category.name
          );
          const pictureSrc = picture[0] && {
            name: picture[0].title,
            src: picture[0].src,
          };

          return (
            <>
              <Category
                key={`category-${index}`}
                category={category}
                pictureSrc={pictureSrc}
                data={dataForCategory}
              />
              <MobileCategory
                key={`mobile-category-${index}`}
                category={category}
                pictureSrc={pictureSrc}
                data={dataForCategory}
              />
            </>
          );
        }
      })}
    </div>
  );
}
export default Categories;
