import React, {useState} from 'react'
import {Text, View, Image} from '@react-pdf/renderer';
import styles from './styles'
import {demoItemListPNG} from '../../constants';

const DemoListPNG = ({data}) => {

    const [itemList] = useState(demoItemListPNG.map(e => ({...e, active: false})))

    const InspectionIncludeItem = ({item, isActive}) => {
        const {ICON, title} = item;
        return (
            <View style={styles.block}>
                <Image source={ICON} style={isActive ? styles.activeImage : styles.disableImage}/>
                <Text style={styles.text}>{title}</Text>
            </View>
        )
    }

    return (
        <View style={styles.container}>
            {
                itemList.map((e, index) => <InspectionIncludeItem key={index} item={e} isActive={data[index]}/>)
            }
        </View>
    )
};

export default DemoListPNG