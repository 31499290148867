import React, {useState} from "react";
import {Text, View} from "@react-pdf/renderer";
import styles from "./styles";

const SideInfoBlock = ({data}) => {
    let [date, time] = data.inspection_date_time.split(" ");
    const demoDetailList = [
        {
            title: "Client",
            value:
                data.customer && data.customer.user
                    ? `${data.customer.user.first_name} ${data.customer.user.last_name}`
                    : "Not found"
        },
        {
            title: "Location",
            value: "Not Specified"
        },
        {
            title: "Inspector",
            value: data.inspection ? data.inspection.technician.name : "Not found"
        },
        {
            title: "Appointment ID",
            value: data.id || "Not found"
        },
        {
            title: "Inspection type",
            value: "Standart"
        },
        {
            title: "Inspected car",
            value: data.car_info
                ? `${data.car_info.model} ${data.car_info.vehicle_type} ${data.car_info.plate_number}`
                : "Not found"
        },
        {
            title: "Date and time of inspection",
            value: `${date}/${time.split(":").slice(0, 2).join(":")}` || "Not found"
        }
    ];

    const [detailList] = useState(demoDetailList);

    const DetailEntry = ({entry}) => {
        return (
            <View style={styles.detailBlock}>
                <Text style={styles.detailTitle}>{entry.title}</Text>
                <Text style={styles.detailValue}>{entry.value}</Text>
            </View>
        );
    };

    return (
        <View style={styles.container}>
            {detailList.map((e, index) => (
                <DetailEntry key={index} entry={e}/>
            ))}
        </View>
    );
};

export default SideInfoBlock;
