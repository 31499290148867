import {StyleSheet} from "@react-pdf/renderer"

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        fontSize: 17,
        justifyContent: 'space-around',
        borderColor: '#eee',
        borderRadius: 10,
        borderWidth: 2,
        width: 1325,
        alignSelf: 'center',
    },
    block: {
        padding: 20,
    },
    disableImage: {
        height: 40,
        alignSelf: 'center',
        opacity: 0.25,
    },
    activeImage: {
        height: 40,
        alignSelf: 'center',
    },
    text: {
        alignSelf: 'center',
        marginTop: 10,
    },
});

export default styles;
