export const demoItemListPNG = [
  {
    ICON: require("./assets/Report image/Active/car-6.png"),
    title: "Exterior"
  },
  {
    ICON: require("./assets/Report image/Active/engine.png"),
    title: "Interior"
  },
  {
    ICON: require("./assets/Report image/Active/clock.png"),
    title: "Frame"
  },
  {
    ICON: require("./assets/Report image/Active/Group 7.png"),
    title: "Mechanical"
  },
  {
    ICON: require("./assets/Report image/Active/steering-wheel.png"),
    title: "Electric"
  },
  {
    ICON: require("./assets/Report image/Active/plug.png"),
    title: "Tires"
  },
  {
    ICON: require("./assets/Report image/Active/test.png"),
    title: "Test drive"
  },
  {
    ICON: require("./assets/Report image/Active/video-player.png"),
    title: "Video"
  },
  {
    ICON: require("./assets/Report image/Active/car.png"),
    title: "History"
  }
];

export const fullMonthsArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const blueA = "#1A76D2";
export const blackA = "#212529";

export const CATEGORY_STATUS_TYPE = {
  PERFECT: "✔",
  DAMAGED: "?",
  // CRITICAL: "!"
};

export const palette = {
  "white": "white",
  "black": "black",
  "grey": "#aaa",
  "blue": "#5956FF",
  "red": "#df3b3f",
  "yellow": "#ffdc56",
  "green": "#4da34d",
  "purple": "#b254ff",
  "brown": "#8f6300"
};

export const N_CRITICAL_SEVERITY = 4;
