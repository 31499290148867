import React from "react";
import "./index.css";

function MobileTitleBlock({ id, vin }) {
  return (
    <div className="mobile-title-report">
      <p className="report-title">
        CONDITION REPORT <span className="report-number">#{id}</span>
      </p>
      {vin && (
        <div className="vin-block">
          <span className="vin-item">VIN</span>
          <span className="vin-item vin-number">{vin.toUpperCase()}</span>
        </div>
      )}
    </div>
  );
}
export default MobileTitleBlock;
