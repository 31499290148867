import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import "./index.css";
import TableContent from "./TableContent";
import { LazyLoadComponent } from "react-lazy-load-image-component";

function CategoryTiresTable({ category }) {
  const [data, setData] = useState([]);

  const columns = [
    {
      title: "",
      dataIndex: "description",
      key: "tire-description",
      render: (text) => <>{text}</>,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (text) => <>{text}</>,
    },
    {
      title: "Tire Damage",
      dataIndex: "damage",
      key: "tire-damage",
      render: (text) => <>{text}</>,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (text) => <>{text}</>,
    },
    {
      title: "Tread",
      dataIndex: "tread",
      key: "tread",
      render: (text) => <>{text}/32''</>,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "tire-notes",
      render: (text) => <>{text}</>,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "tire-image",
      width: "200px",
      render: (images) => {
        let photos = [];

        for (let i = 0; i < images.length; i++) {
          let imagePath = images[i].replace(/images\//gi, "");

          photos.push({
            original: `${process.env.REACT_APP_API_PATH}/images/resize/${imagePath}?w=600`,
          });
        }

        return images.length > 0 ? (
          <LazyLoadComponent>
            <ImageGallery
              items={photos}
              useBrowserFullscreen={false}
              showThumbnails={false}
              infinite={false}
              lazyLoad
              showPlayButton={false}
            />
          </LazyLoadComponent>
        ) : (
          <div className="no-image"></div>
        );
      },
    },
  ];

  useEffect(() => {
    let arrayOfData = [];

    category &&
      category.subcategories.map((subcategory, index) => {
        let subcategoryName = "";
        let tireBrand = "";
        let tireSize = "";
        let tireTread = "";
        let tireDamage = "";

        if (
          subcategory.steps[0].damage !== "OK" &&
          subcategory.steps[0].damage !== "None"
        ) {
          tireDamage = subcategory.steps[0].damage;
        }

        subcategory.steps[0].specialFactors.map((factor) => {
          if (factor.name === "Tire Manufacturer") {
            tireBrand = factor.value;
          } else if (factor.name === "Tire Size") {
            tireSize = factor.value;
          } else if (factor.name === "Tire Tread") {
            tireTread = factor.value;
          }
        });

        if (subcategory.name === "L FRONT") {
          subcategoryName = "Left Front";
        } else if (subcategory.name === "L REAR") {
          subcategoryName = "Rear Left";
        } else if (subcategory.name === "R FRONT") {
          subcategoryName = "Right Front";
        } else if (subcategory.name === "R REAR") {
          subcategoryName = "Rear Right";
        } else if (subcategory.name === "SPARE") {
          subcategoryName = "Spare Tire";
        }

        arrayOfData.push({
          description: subcategoryName,
          brand: tireBrand,
          size: tireSize,
          tread: tireTread,
          damage: tireDamage,
          notes: subcategory.steps[0].note || "",
          image: subcategory.steps[0].photos || "",
        });
      });

    setData(arrayOfData);
  }, [category]);

  return (
    <>
      <div className="category-table tires-table">
        {data?.length > 0 && (
          <Table columns={columns} dataSource={data} pagination={false} />
        )}
      </div>

      {data?.length > 0 && (
        <div className="category-table-mobile">
          <div className="table-mobile__header">
            <span className="table-mobile__column-name">Description</span>
            <span className="table-mobile__column-name">Tire Size</span>
          </div>

          {data.map((step, index) => {
            return (
              <TableContent
                description={step.description}
                damage={step.damage}
                images={step.image}
                size={step.size}
                tread={step.tread}
                brand={step.brand}
                notes={step.notes}
                key={String(index)}
              />
            );
          })}
        </div>
      )}
    </>
  );
}
export default CategoryTiresTable;
