import {StyleSheet} from "@react-pdf/renderer"

const styles = StyleSheet.create({
    container: {
        // padding: 20,
        fontSize: 13,
        borderColor: '#eee',
        borderRadius: 10,
        borderWidth: 2,
        width: 450,
        height: 250,
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    detailBlock: {
        width: '50%',
        padding: 20,
    },
});

export default styles;
