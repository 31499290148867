import React from "react";

export default function IconOtherColor() {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            xmlns="http://www.w3.org/2000/svg"
            style={{marginLeft: "10px"}}
        >
            <defs>
                <radialGradient id="Gradient" cx="0.15" cy="0.15" r="1">
                    <stop offset="0%" stopColor="red"/>
                    <stop offset="35%" stopColor="pink"/>
                    <stop offset="50%" stopColor="blue"/>
                    <stop offset="75%" stopColor="orange"/>
                    <stop offset="100%" stopColor="green"/>
                </radialGradient>
            </defs>

            <rect
                x="10"
                y="10"
                width="15"
                height="15"
                style={{stroke: "gray", strokeWidth: 0.5}}
                rx="3"
                ry="3"
                fill="url(#Gradient)"
            />
        </svg>
    );
}
