import {StyleSheet} from "@react-pdf/renderer"

const styles = StyleSheet.create({
    containerForBorder: {
        height: 250,
        width: 450,
        borderColor: '#eee',
        borderRadius: 10,
        borderWidth: 2,
    },
    container: {
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        height: '80%',
        paddingHorizontal: 10,
    },
    main: {
        fontSize: 17,
        width: '100%',
        padding: 20,
    },
    text: {
        padding: 7,
        fontSize: 13,
        width: '50%',
    },
    galochka: {
        margin: 2,
    },
});

export default styles;
