import models from "./models";

import { useParams, useLocation } from "react-router-dom";

export const fetchPhoto = async (photoPath) => {
  const imagePath = photoPath.replace(/images\//gi, "");
  return `${process.env.REACT_APP_API_PATH}/images/resize/${imagePath}?w=600`;
};

export const fetchMultiplePhotos = async (photos) => {
  if (!photos || photos.length <= 0) {
    return [];
  }

  const urls = photos.map(async (photo) => {
    return await fetchPhoto(photo);
  });

  return Promise.all(urls);
};

export const resizedPhotoUrl = (path, width = 600) => {
  const imagePath = path.replace(/images\//gi, "");
  return `${process.env.REACT_APP_IMAGES_URL}/${imagePath}?w=${width}`;
};

export const getModel = (path) => {
  const [v, b] = path.split("/");
  return models[v][b];
};

export const parseStepsFromCategory = (category, displayFailed = false) => {
  let steps = [];
  category.subcategories.forEach((e) => {
    e.steps.forEach((s) => {
      // const severity = s.factors.find(f => f.id === 2).results.title;
      // if (displayFailed && parseInt(severity) <= 0) {
      //   return;
      //   // display only failed steps
      // }
      steps.push({
        name: s.name,
        subcategory: e.name,
        photos: s.photos,
        note: s.note,
        areas: e.areas,
        recommendation: e.recommendation,
        severity: e.severity,
        damage: e.damage,
      });
    });
  });

  return steps;
};

export const useAppParams = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const { appointmentID } = useParams();

  let query = useQuery();
  const format = query.get("format");
  const type = query.get("type");
  const outdated = query.get("outdated");

  return { appointmentID, format, type, outdated };
};
export default parseStepsFromCategory;
