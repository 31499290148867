import React from 'react'
import {Text, View, Image, Link} from '@react-pdf/renderer';
import styles from './styles'

const Header = () => {
    return (
        <View style={styles.header}>
            <View>
                <Image style={styles.logoEllipse} source={require('../../assets/logo_ellips.png')}/>
                <Text style={styles.logoText}>LOGO</Text>
            </View>
            <Text style={styles.headerReport}>INSPECTION REPORT</Text>
            <Text style={styles.headerLink}><Link src={'#'}>CARTITUDE</Link></Text>
        </View>
    )
};

export default Header