import React, { useEffect, useState } from "react";
import "../index.css";
import CategoryTable from "../CategoryTable";
import CategoryTiresTable from "../CategoryTiresTable";
import Collapse from "@material-ui/core/Collapse";
import { _getCategoryCondition } from "./categoryCondition";
import MobileSectionTitle from "../../../MobileSectionTitle";
import TiresIcon from "../../../icons/categories_/TiresSvg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

function MobileCategory({ category, pictureSrc, data }) {
  const [collapse, setCollapse] = useState(false);
  const [colorsObject, setColorsObject] = useState({});

  useEffect(() => {
    let colorsObj = {};
    let color = "";

    if (category?.name === "TIRES") {
      category.subcategories.map((subcategory, i) => {
        subcategory.steps[0].specialFactors.map((factor) => {
          if (factor.name === "Tire Tread") {
            if (+factor.value >= 6) {
              color = "#74ce01";
            } else if (+factor.value >= 4 && +factor.value <= 5) {
              color = "#ffe600";
            } else if (+factor.value < 4) {
              color = "grey";
            }
            colorsObj[subcategory.name.replace(/\s/g, "")] = color;
          }
        });

        if (i === category.subcategories.length - 1) {
          setColorsObject(colorsObj);
        }
      });
    }
  }, []);

  const getTiresBlock = (arr, right, bottom) => {
    return arr.map((subcategory, i) => {
      let manufacturer = "";
      let tread = "";
      let size = "";

      subcategory.steps[0].specialFactors.map((factor) => {
        if (factor.name === "Tire Manufacturer") {
          manufacturer = factor.value;
        } else if (factor.name === "Tire Size") {
          size = factor.value;
        } else if (factor.name === "Tire Tread") {
          tread = `${factor.value}/32''`;
        }
      });

      return (
        <div
          className={`tires-block ${i === 0 && !bottom && "tires-block-top"} ${
            right && "tires-block-right"
          }`}
        >
          <span className="text-bold">{subcategory.name}</span>
          <span className="tires-brand">{manufacturer}</span>
          <span className="text-bold tires-tread">{tread}</span>
          <span>{size}</span>
        </div>
      );
    });
  };

  return (
    <div>
      <MobileSectionTitle
        title={category.name}
        containerStyle={{ marginTop: 40 }}
        collapse={collapse}
        setCollapse={setCollapse}
        id={`${category.name}-mob`}
      />
      <Collapse
        in={!collapse}
        timeout="auto"
        unmountOnExit
        className="category-collapse-mobile"
      >
        <div className="category">
          <div className="category-container-mobile">
            {category.name !== "TIRES" && (
              <div className="category-picture">
                <LazyLoadImage
                  src={pictureSrc.src}
                  alt={category.name}
                  effect="opacity"
                />
              </div>
            )}

            {category.name === "TIRES" && (
              <div className="category-picture category-picture-tires">
                <div className="left-tires">
                  {getTiresBlock(category.subcategories.slice(0, 2))}
                </div>
                <TiresIcon
                  spare={colorsObject?.SPARE}
                  lFront={colorsObject?.LFRONT}
                  lRear={colorsObject?.LREAR}
                  rRear={colorsObject?.RREAR}
                  rFront={colorsObject?.RFRONT}
                />

                <div className="right-tires">
                  {getTiresBlock(category.subcategories.slice(2, 4), true)}
                </div>
              </div>
            )}

            {category.name === "TIRES" && (
              <div className="category-tires-bottom">
                <div>
                  <div className="tires-circle-container">
                    <div className="tires-circle green"></div>6/32+
                  </div>
                  <div className="tires-circle-container">
                    <div className="tires-circle yellow"></div>5/32 to 4/32
                  </div>
                  <div className="tires-circle-container">
                    <div className="tires-circle red"></div> {`< 4/32`}
                  </div>
                  <div className="tires-circle-container">
                    <div className="tires-circle grey"></div>N/A
                  </div>
                </div>
                <div>
                  {getTiresBlock(category.subcategories.slice(4), true, true)}
                </div>
              </div>
            )}

            <div className="category-info">
              <_getCategoryCondition category={category} data={data} />
            </div>
          </div>

          {category.name !== "TIRES" && <CategoryTable category={category} />}

          {category.name == "TIRES" && (
            <CategoryTiresTable category={category} />
          )}
        </div>
      </Collapse>
    </div>
  );
}
export default MobileCategory;
