import React, { useState } from "react";
import "./index.css";
import logo from "../icons/logo.jpg";
import { ReactComponent as LogoCaro } from "../icons/caro-logo.svg";
import { ReactComponent as GooglePlay } from "../icons/google-play.svg";
import { ReactComponent as AppStore } from "../icons/app-store.svg";
import { ReactComponent as Facebook } from "../icons/Facebook.svg";
import { ReactComponent as Twitter } from "../icons/Twitter.svg";
import { ReactComponent as Instagram } from "../icons/Instagram.svg";
import HamburgerMenu from "react-hamburger-menu";

function Header({ id }) {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  return (
    <>
      <div className="header-container">
        <a
          href={`${process.env.REACT_APP_FRONTEND_URL}/`}
          target="_blank"
          className="header-logo__link"
        >
          <img src={logo} alt="Logo" className="header-logo__pic" />
        </a>

        <h2 className="header-title">Condition report #{id}</h2>

        <div className="header-info">
          <div className="header-links">
            <div className="header-links__stores">
              <a
                href="https://apps.apple.com/us/app/caro-inspection/id1499547913"
                target="_blank"
              >
                <AppStore className="header-link__store" />
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=com.cartitude.cartitude "
                target="_blank"
              >
                <GooglePlay className="header-link__store" />
              </a>
            </div>

            <div className="header-links__social-networks">
              <span>Follow Us</span>
              <div className="header-links__network-icons">
                <a
                  href={`${process.env.REACT_APP_FRONTEND_URL}/`}
                  target="_blank"
                >
                  <Facebook className="header-link__network" />
                </a>
                <a
                  href={`${process.env.REACT_APP_FRONTEND_URL}/`}
                  target="_blank"
                >
                  <Twitter className="header-link__network" />
                </a>
                <a
                  href={`${process.env.REACT_APP_FRONTEND_URL}/`}
                  target="_blank"
                >
                  <Instagram className="header-link__network network-last" />
                </a>
              </div>
            </div>
          </div>

          <div className="header-contacts">
            <a
              href={`${process.env.REACT_APP_FRONTEND_URL}/`}
              target="_blank"
              className="header-contacts__contact"
            >
              www.caroinspection.com
            </a>
            <p className="header-contacts__contact">
              support@caroinspection.com
            </p>
            <p className="header-contacts__contact">619 955 9535</p>
          </div>
        </div>
      </div>

      <div className="header-container-mobile">
        <div className="header-empty-mobile"></div>
        <a
          href={`${process.env.REACT_APP_FRONTEND_URL}/`}
          target="_blank"
          className="link-header-mobile"
        >
          <LogoCaro />
        </a>
        <div className="menu-header-mobile">
          <HamburgerMenu
            color="white"
            width={30}
            height={18}
            isOpen={isOpenMenu}
            menuClicked={() => setIsOpenMenu(!isOpenMenu)}
          />
        </div>
      </div>
      {isOpenMenu && (
        <div className="menu-header-mobile__content">
          <a
            href="https://apps.apple.com/us/app/caro-inspection/id1499547913"
            target="_blank"
          >
            <AppStore className="header-link__store" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.cartitude.cartitude "
            target="_blank"
          >
            <GooglePlay className="header-link__store" />
          </a>

          <div className="header-links__network-icons">
            <a href={`${process.env.REACT_APP_FRONTEND_URL}/`} target="_blank">
              <Facebook className="header-link__network" />
            </a>
            <a href={`${process.env.REACT_APP_FRONTEND_URL}/`} target="_blank">
              <Twitter className="header-link__network" />
            </a>
            <a href={`${process.env.REACT_APP_FRONTEND_URL}/`} target="_blank">
              <Instagram className="header-link__network network-last" />
            </a>
          </div>

          <div className="header-contacts">
            <a
              href={`${process.env.REACT_APP_FRONTEND_URL}/`}
              target="_blank"
              className="header-contacts__contact"
            >
              www.caroinspection.com
            </a>
            <p className="header-contacts__contact">
              support@caroinspection.com
            </p>
            <p className="header-contacts__contact">619 955 9535</p>
          </div>
        </div>
      )}
    </>
  );
}
export default Header;
