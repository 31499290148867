import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { IconButton } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import "./index.css";
import { LazyLoadComponent } from "react-lazy-load-image-component";

function TableContent({ description, damage, images, notes }) {
  const [visibleContent, setVisibleContent] = useState(true);

  let photos = [];

  for (let i = 0; i < images.length; i++) {
    let imagePath = images[i].replace(/images\//gi, "");

    photos.push({
      original: `${process.env.REACT_APP_API_PATH}/images/resize/${imagePath}?w=600`,
    });
  }

  return (
    <div className="table-mobile__content">
      <div
        className="table-mobile__content-visible"
        onClick={() => setVisibleContent(!visibleContent)}
      >
        <span className="table-mobile__content-inner">{description}</span>
        <span className="table-mobile__content-inner">{damage}</span>
        <IconButton
          style={{ color: "#2e2e2e30" }}
          aria-label="expand row"
          size="large"
          onClick={() => setVisibleContent(!visibleContent)}
        >
          {visibleContent ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </IconButton>
      </div>
      {visibleContent && (
        <div className="table-mobile__content-hidden">
          {images.length > 0 ? (
            <LazyLoadComponent>
              <ImageGallery
                items={photos}
                useBrowserFullscreen={false}
                showThumbnails={false}
                infinite={false}
                lazyLoad
                showPlayButton={false}
              />
            </LazyLoadComponent>
          ) : (
            <div className="no-image"></div>
          )}
          <span className="notes-content">{notes}</span>
        </div>
      )}
    </div>
  );
}
export default TableContent;
