import {StyleSheet} from "@react-pdf/renderer";

const styles = StyleSheet.create({
    containerForBorder: {
        borderColor: "#eee",
        borderRadius: 10,
        borderWidth: 2,
        width: 1325,
        alignSelf: "center",
        marginVertical: 20
    },
    row: {
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomColor: "#eee",
        marginHorizontal: 20
    },
    dataRow: {
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomColor: "#eee"
        // width: "50%",
    },
    header: {
        // alignItems: "flex-start",
        // flexWrap: "wrap",
        display: "flex",
        flexDirection: "row",
        height: 60,
        // alignSelf: "center",
        width: "100%"
    },
    headerRow: {
        // width: "50%",
        flex: 1,
        display: "flex",
        alignItems: "space-evenly",
        flexDirection: "row",
        margin: 5
    },
    bodySub: {
        width: "48%",
        // flex: 1,
        display: "flex",
        backgroundColor: "#f1f1f1",
        // alignItems: "space-evenly",
        flexDirection: "row",
        margin: 10,
        height: 25
    },
    text: {
        fontSize: 17,
        margin: 2,
        flex: 1
    },

    exterior: {
        fontSize: 38,
        left: 55,
        marginVertical: 20
    }
});

export default styles;
