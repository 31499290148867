import React, { useEffect, useState } from "react";
import Badge from "./Badge";
import BadgeForMobile from "./BadgeForMobile";
import "./index.css";

function ResultsBadges({ data }) {
  const [badges, setBadges] = useState([]);
  const [firstBadges, setFirstBadges] = useState([]);
  const [secondBadges, setSecondBadges] = useState([]);
  const [thirdBadges, setThirdBadges] = useState([]);
  const [badgesOpened, setBadgesOpened] = useState(false);

  useEffect(() => {
    let badgesOfCategories = [];

    data?.categories &&
      data.categories.map((category) => {
        if (category.name === "REQUIRED") {
          let isDamaged = false;
          let name = null;
          let specialFactors = [];

          category.subcategories[0].steps.map((step) => {
            name = step.name;

            if (step.damage !== ("OK" || "None")) {
              isDamaged = true;
            }

            if (step.name === "KEYS") {
              step.specialFactors.map((factor) => {
                factor.value !== "-1" &&
                  specialFactors.push({
                    name: factor.name,
                    value: factor.value,
                  });
              });
            }

            if (step.name === "ODOR") {
              step.specialFactors.map((factor) => {
                  isDamaged = factor.value === "Bad" ? true : false
              });
            }

            step.name === "KEYS" &&
              badgesOfCategories.push({
                name,
                damage: isDamaged,
                specialFactors,
              });

              step.name === "ODOR" &&
              badgesOfCategories.push({
                name,
                damage: isDamaged,
                odorValue: isDamaged ? "BAD" : "GOOD",
              });
          });
        } else {
          let isDamaged = false;

          category.subcategories.map((subcategory) => {
            if (subcategory.maxSeverity > 0) {
              isDamaged = true;
            }
          });

          badgesOfCategories.push({
            name: category.name,
            damage: isDamaged,
          });
        }
      });


      data?.pickedVehicleDetails?.painted && badgesOfCategories.push({
        name: "PAINT*",
        paintValue: data?.pickedVehicleDetails?.painted
      })

    setBadges(badgesOfCategories);
  }, []);

  useEffect(() => {
    if (badges?.length > 0) {
      if (badges.length === 8) {
        setFirstBadges(badges.slice(0, 4))
        setSecondBadges(badges.slice(4))
      } else if (badges.length > 8) {
        setFirstBadges(badges.slice(0, 4))
        setSecondBadges(badges.slice(4, 8))
        setThirdBadges(badges.slice(8))
      }
    }
  }, [badges])

  return (
    <>
    <div className="results-badges">
      {badges.map((badge, index) => {
        return <Badge badge={badge} key={`badge-${index}`}/>;
      })}
    </div>

    <span className="report-small-title">Overview</span>

    <div className="results-badges-mobile">
    {firstBadges && firstBadges.map((badge, index) => {
        return <BadgeForMobile badge={badge} key={`badge-${index}`}/>;
      })}
    </div>

    <div className="results-badges-mobile">
    {secondBadges && secondBadges.map((badge, index) => {
        return <BadgeForMobile badge={badge} key={`badge-${index}`}/>;
      })}
    </div>

    <div className="results-badges-mobile">
    {thirdBadges && badgesOpened && thirdBadges.map((badge, index) => {
        return <BadgeForMobile badge={badge} key={`badge-${index}`}/>;
      })}
    </div>

    {badges?.length > 8 && <button onClick={() => setBadgesOpened(!badgesOpened)} className="read-more-btn badges-read-more">{badgesOpened ? `Show less` : `Show more`}</button>}
    </>
  );
}
export default ResultsBadges;
