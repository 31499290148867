import React from "react";
import ButtonToTop from "./ButtonToTop";
import FixedBlockWithCar from "./FixedBlockWithCar";
import Footer from "./Footer";
import Header from "./Header/index";
import "./index.css";
import InspectionResults from "./InspectionResults";
import MainInfo from "./MainInfo";

function HTMLResultUpdated({ data, appointmentId }) {
  return (
    <div className="html-main">
      <Header id={appointmentId} />
      <FixedBlockWithCar attributes={data.attributes} image={data?.overviewPhotosSrc[0]}/>
      <ButtonToTop />
      <MainInfo data={data} id={appointmentId}/>
      <InspectionResults data={data}/>
      <Footer />
    </div>
  );
}
export default HTMLResultUpdated;
