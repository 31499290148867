import React from "react";
import {palette} from "../../constants";

export default function IconColor({text}) {
    return (
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            xmlns="http://www.w3.org/2000/svg"
            style={{marginLeft: "10px"}}
        >
            <rect
                x="0"
                y="0"
                width="15"
                height="15"
                style={{stroke: "gray", strokeWidth: 0.5}}
                rx="3"
                ry="3"
                fill={palette[text.slice(16).toLowerCase()]}
            />
        </svg>
    );
}