import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { fetchMultiplePhotos } from "../../../../helpers";
import "./index.css";
import TableContent from "./TableContent";
import { LazyLoadComponent } from "react-lazy-load-image-component";

function CategoryTable({ category }) {
  const [data, setData] = useState([]);
  let i = 0;

  const columns = [
    {
      title: "Part Name",
      dataIndex: "description",
      key: "description",
      render: (text) => <>{text}</>,
    },
    {
      title: "Damage",
      dataIndex: "damage",
      key: "damage",
      render: (text) => <>{text}</>,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (text) => <>{text}</>,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: "200px",
      render: (images) => {
        let photos = [];

        for (let i = 0; i < images.length; i++) {
          let imagePath = images[i].replace(/images\//gi, "");

          photos.push({
            original: `${process.env.REACT_APP_API_PATH}/images/resize/${imagePath}?w=600`,
          });
        }

        return images.length > 0 ? (
          <LazyLoadComponent>
            <ImageGallery
              items={photos}
              useBrowserFullscreen={false}
              showThumbnails={false}
              infinite={false}
              lazyLoad
              showPlayButton={false}
            />
          </LazyLoadComponent>
        ) : (
          <div className="no-image"></div>
        );
      },
    },
  ];

  useEffect(() => {
    let arrayOfData = [];

    category &&
      category.subcategories.map((subcategory, index) => {
        subcategory.steps.map((step) => {
          i = i + 1;
          if (
            (step.damage !== "None" && step.damage !== "OK") ||
            step.photos.length > 0 ||
            step.note
          ) {
            let damage =
              step.damage === "OK" || step.damage === "None" ? "" : step.damage;

            arrayOfData.push({
              description: step.name,
              damage,
              notes: step.note || "",
              image: step.photos || "",
              key: `${step.name}-${index}`,
            });
          }
        });
      });

    setData(arrayOfData);
  }, [category]);

  return (
    <>
      <div className="category-table">
        {data?.length > 0 && (
          <Table columns={columns} dataSource={data} pagination={false} />
        )}
      </div>

      {data?.length > 0 && (
        <div className="category-table-mobile">
          <div className="table-mobile__header">
            <span className="table-mobile__column-name">Part Name</span>
            <span className="table-mobile__column-name">Damage</span>
          </div>

          {data.map((step) => {
            return (
              <TableContent
                description={step.description}
                damage={step.damage}
                images={step.image}
                notes={step.notes}
              />
            );
          })}
        </div>
      )}
    </>
  );
}
export default CategoryTable;
