import React from 'react'
import {Text, View, Image} from '@react-pdf/renderer';
import styles from './styles'

const HighValueOptions = ({data}) => {

    const highValueOptions = [
        '  Bluetooth Connection',
        '  Climate Control',
        '  Fog Lamps',
        '  Integrated Turn Signal Mirrors',
        '  Keyless Start',
        '  Multi-Zone A / C',
        '  Rain Sensing Wipers',
        '  Seat Memory',
        '  Tire Pressure Monitor',
        '  Woodgrain Interior Trim',
        '  Power Driver Seat',
        '  Power Passenger Seat'
    ]

    return (
        <View style={styles.containerForBorder}>
            <Text style={styles.main}>High Value Options</Text>
            <View style={styles.container}>
                {
                    highValueOptions.map((e, index) => (
                        <Text key={index} style={styles.text}>
                            <Image source={require('../../assets/choose.png')}/>
                            {e}
                        </Text>
                    ))
                }
            </View>
        </View>
    )
};

export default HighValueOptions