import React, {useState} from 'react'
import {Text, View} from '@react-pdf/renderer';
import styles from './styles'

const CarInfoBlock = ({data}) => {

    const demoDetailList = [
        {
            title: 'REGISTRATION YEAR',
            value: data.inspection ? data.inspection.vehicle_details.register_year : 'Not found',
        },
        {
            title: 'TRANSMISSION',
            value: data.inspection ? data.inspection.vehicle_details.transmission : 'Not found',
        },
        {
            title: 'MILAGE (KM)',
            value: data.inspection ? data.inspection.vehicle_details.milage : 'Not found',
        },
        {
            title: 'VEHICAL TYPE',
            value: data.inspection ? data.inspection.vehicle_details.vehicle_type.name : 'Not found',
        },
        {
            title: 'TRIM',
            value: data.inspection ? data.inspection.vehicle_details.trim : 'Not found',
        },
        {
            title: 'OPEN RECALS',
            value: 'YES/NO',
        },
    ]

    const [detailList] = useState(demoDetailList);

    const DetailEntry = ({entry}) => {
        return (
            <View style={styles.detailBlock}>
                <Text>{entry.title}</Text>
                <Text>{entry.value}</Text>
            </View>
        )
    }

    return (
        <View style={styles.container}>
            {
                detailList.map((e, index) => <DetailEntry key={index} entry={e}/>)
            }
        </View>
    )
};

export default CarInfoBlock