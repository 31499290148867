import React, {useState} from "react";
import {Text, View, Image} from "@react-pdf/renderer";

const Information = ({e}) => {
    return (
        <View style={{flex: 1}}>
            <Text
                style={{
                    fontSize: 20,
                    marginBottom: 22,
                    color: "#444",
                    textAlign: "left"
                }}
            >
                {e.name}
            </Text>

            <Text
                style={{
                    fontSize: 20,
                    fontWeight: 400,
                    color: "#444",
                    marginBottom: 5
                }}
            >
                Severity:{" "}
                <Text style={{fontSize: 20, fontWeight: 400, color: "#f66"}}>
                    {e.severity}
                </Text>
            </Text>
            <Text
                style={{
                    fontSize: 20,
                    fontWeight: 400,
                    color: "#444",
                    marginBottom: 5
                }}
            >
                Damage:{" "}
                <Text style={{fontSize: 20, fontWeight: 400, color: "#f66"}}>
                    {e.damage}
                </Text>
            </Text>
            <Text
                style={{
                    fontSize: 20,
                    fontWeight: 400,
                    color: "#444",
                    marginBottom: 5
                }}
            >
                Recomendation:{" "}
                <Text style={{fontSize: 20, fontWeight: 400, color: "#444"}}>
                    {e.recommendation}
                </Text>
            </Text>
            <Text
                style={{
                    fontSize: 20,
                    fontWeight: 400,
                    color: "#444",
                    marginBottom: 5
                }}
            >
                Note:{" "}
                <Text style={{fontSize: 20, fontWeight: 400, color: "#444"}}>
                    {e.note || "The inspector has not provided a not"}
                </Text>
            </Text>
        </View>
    );
};
const Item = ({e}) => {
    return (
        <View
            style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "row",
                margin: "auto",
                width: "80vw",
                borderColor: "#eee",
                borderRadius: 3,
                borderBottomWidth: 2,
                marginBottom: 20,
                padding: 30
            }}
        >
            <View
                style={{
                    display: "flex",
                    flex: 1.6,
                    flexDirection: "row",
                    flexWrap: "wrap"
                }}
            >
                {e.photos.map(base64 => (
                    <Image
                        style={{
                            width: 300,
                            margin: 5,
                            borderRadius: 3
                        }}
                        source={`data:image/jpeg;base64,${base64}`}
                    />
                ))}
            </View>
            <Information e={e}/>
        </View>
    );
};

const StepGroup = ({category}) => {
    return (
        <View style={{width: "80vw", margin: 'auto'}}>
            <Text
                style={{
                    fontSize: 34,
                    margin: 15,
                    color: "#ddd",
                    textAlign: "left"
                }}
            >
                {category.name}
            </Text>
            {category.steps.map((step, index) => (
                <Item key={index} e={step}/>
            ))}
        </View>
    );
};

const DetailedBlock = ({categories}) => {
    const getTargetCategories = src => {
        let c;
        c = src.map(category => ({
            name: category.name,
            steps: category.steps.filter(step => step.photos)
        }));
        c = c.filter(category => category.steps && category.steps.length > 0);

        return c;
    };
    const [targetCategories] = useState(getTargetCategories(categories));

    return (
        <View
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px auto 20px auto",
                width: "100%"
            }}
        >
            {targetCategories.map((category, index) => (
                <StepGroup key={index} category={category}/>
            ))}
        </View>
    );
};

export default DetailedBlock;
