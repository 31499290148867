import React, { useEffect, useState } from "react";
import "./index.css";
import { ReactComponent as TIRES } from "../../../icons/Tabs/TIRES.svg";
import { ReactComponent as EXTERIOR } from "../../../icons/Tabs/EXTERIOR.svg";
import { ReactComponent as INTERIOR } from "../../../icons/Tabs/INTERIOR.svg";
import { ReactComponent as MECHANICAL } from "../../../icons/Tabs/MECHANICAL.svg";
import { ReactComponent as FRAME } from "../../../icons/Tabs/FRAME.svg";
import { ReactComponent as ELECTRIC } from "../../../icons/Tabs/ELECTRICAL.svg";
import { ReactComponent as PAINT } from "../../../icons/Tabs/PAINT.svg";
import { ReactComponent as ODOR } from "../../../icons/Tabs/_ODOR.svg";
import { ReactComponent as KEYS } from "../../../icons/Tabs/KEYS.svg";
import { ReactComponent as GOOD } from "../../../icons/GOOD.svg";
import { ReactComponent as DAMAGED } from "../../../icons/DAMAGED.svg";

function Badge({ badge }) {
  const [badgeObject, setBadgeObject] = useState(badge);

  const arrayOfTabs = [
    { title: "EXTERIOR", icon: EXTERIOR },
    { title: "INTERIOR", icon: INTERIOR },
    { title: "TIRES", icon: TIRES },
    { title: "MECHANICAL", icon: MECHANICAL },
    { title: "ELECTRICAL", icon: ELECTRIC },
    { title: "ODOR", icon: ODOR },
    { title: "KEYS", icon: KEYS },
    { title: "FRAME", icon: FRAME },
    { title: "PAINT*", icon: PAINT },
  ];

  useEffect(() => {
    let object = { ...badge };

    arrayOfTabs.map((tab) => {
      if (tab.title === badge.name) {
        object = {
          ...object,
          icon: tab.icon,
        };
      }
    });

    if (badge.name === "KEYS") {
      if (badge.specialFactors.length === 1) {
        object = {
          ...object,
          name: "KEY-FOBS",
          value: badge.specialFactors[0].value,
        };
      } else {
        object = {
          ...object,
          name: "KEYS/FOBS",
          value: `${badge.specialFactors[0].value}/${badge.specialFactors[1].value}`,
        };
      }
    }

    setBadgeObject(object);
  }, []);

  const BadgeValue = () => {
    if (badgeObject.value) {
      return (
        <span className="badge-value-container badge-value">
          {badgeObject.value}
        </span>
      );
    } else if (
      badgeObject.name === "ODOR" &&
      badgeObject?.odorValue === "GOOD"
    ) {
      return (
        <span className="badge-value-container badge-value-good badge-value__odor">
          {badgeObject.odorValue}
        </span>
      );
    } else if (
      badgeObject.name === "ODOR" &&
      badgeObject?.odorValue === "BAD"
    ) {
      return (
        <span className="badge-value-container badge-value-damaged badge-value__odor">
          {badgeObject.odorValue}
        </span>
      );
    } else if (
      badgeObject.damage ||
      (badgeObject.paintValue && badgeObject.paintValue === "Yes")
    ) {
      return (
        <span className="badge-value-container badge-value-damaged">
          <DAMAGED />
        </span>
      );
    } else if (
      badgeObject.paintValue &&
      badgeObject.paintValue === "Partially painted"
    ) {
      return (
        <span className="badge-value-container badge-value-warning">
          <DAMAGED />
        </span>
      );
    } else {
      return (
        <span className="badge-value-container badge-value-good">
          <GOOD />
        </span>
      );
    }
  };

  return (
    <a href={`#${badgeObject.name}`} className="badge-link">
    <div className="results-badges__badge">
      {badgeObject.icon && badgeObject.name !== "ODOR" && (
        <badgeObject.icon className="badge-icon" />
      )}

      {badgeObject.icon && badgeObject.name === "ODOR" && (
        <div className="badge-icon__odor">
          <badgeObject.icon className="badge-icon" />
        </div>
      )}

      <BadgeValue />

      <span>{badgeObject.name}</span>
    </div>
    </a>
  );
}
export default Badge;
