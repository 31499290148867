import { fetchMultiplePhotos } from "./helpers";
import { CATEGORY_STATUS_TYPE, N_CRITICAL_SEVERITY } from "./constants";

// IMPORTANT INFO: SEVERITY AND RECOMMENDATION REMAIN UNUSED UNTILL BETA LAUNCH
export const formatDataPDF = async (d) => {
  const { categories } = d.inspection;

  const getSteps = async (category) => {
    let allSteps = [];

    const forLoop = async (steps) => {
      for (let index = 0; index < steps.length; index++) {
        let recommendation = null;
        let severity = null;
        let damage = null;
        steps[index].factors.forEach((factor) => {
          if (factor.type === "r") {
            recommendation = factor;
          }
          // else if (factor.type === 's') {
          //   severity = parseInt(factor.value); //FIXME
          // }
          else if (factor.type === "d") {
            damage = factor.value;
          }
        });

        allSteps.push({
          name: steps[index].name,
          damage,
          severity,
          recommendation,
          note: steps[index].results.note,
          photos: await fetchMultiplePhotos(steps[index].results.photos),
        });
      }
    };

    for (let index = 0; index < category.subcategories.length; index++) {
      await forLoop(category.subcategories[index].steps);
    }

    return {
      ...d,
      newCategories: allSteps,
    };
  };

  let newD = categories.map(async (category, index) => ({
    name: category.name,
    steps: await getSteps(category),
  }));

  newD = Promise.all(newD);
  return newD;
};

export const formatSteps = (rawArray) => {
  let maxSeverity = 0;
  let hasStepNotes = false;

  const data = rawArray.map((s, index) => {
    const specialFactors = [];
    let recommendation = null;
    let severity = null;
    let damage = "OK";
    s.factors.forEach((factor) => {
      if (factor.type === "r") {
        recommendation = factor.value;
      }
      // else if (factor.type === 's') {
      // FIXME
      //   severity = factor.value === "None" ? 0 : parseInt(factor.value);
      // }
      else if (factor.type === "d") {
        damage = factor.value || "OK";
        severity = damage === "OK" || damage === "None" ? 0 : 3;
      } else if (factor.type === "special" || factor.type === "tt") {
        specialFactors.push(factor);
      }
    });

    if (severity > maxSeverity) {
      maxSeverity = severity;
    }

    if (s.results.note) {
      hasStepNotes = true;
    }

    return {
      name: s.name,
      damage,
      severity,
      recommendation,
      note: s.results.note,
      photos: s.results.photos,
      specialFactors,
    };
  });

  return [data, maxSeverity, hasStepNotes];
};

export const formatSubcategories = (arr) => {
  let countMaxSeverity = 0;
  const data = arr.map((sub) => {
    const [steps, maxSeverity, hasStepNotes] = formatSteps(sub.steps);

    if (maxSeverity > countMaxSeverity) {
      countMaxSeverity = maxSeverity;
    }
    return {
      ...sub,
      steps,
      maxSeverity,
      hasStepNotes,
    };
  });

  let status;
  if (countMaxSeverity === 0) {
    status = CATEGORY_STATUS_TYPE.PERFECT;
  } else if (countMaxSeverity < N_CRITICAL_SEVERITY) {
    status = CATEGORY_STATUS_TYPE.DAMAGED;
  }
  // else {
  //   status = CATEGORY_STATUS_TYPE.CRITICAL;
  // }
  return [data, status];
};

export const formatCategories = (arr) => {
  return arr.map((cat) => {
    const [subcategories, status] = formatSubcategories(cat.subcategories);
    return {
      ...cat,
      subcategories,
      status,
    };
  });
};

export const formatDataHTML = async (d) => {
  const {
    inspection,
    src_car_info,
    technician,
    inspection_time_from,
    updated_at,
    is_our_location,
  } = d;
  let inspectionLocation;

  if (is_our_location) {
    if (technician && technician.appartment) {
      inspectionLocation = `${technician.user.first_name},
              ${technician.street},
              ${technician.appartment},
              ${technician.city},
              ${technician.zip}`;
    } else if (technician && !technician.appartment) {
      inspectionLocation = `${technician.user.first_name},
              ${technician.street},
              ${technician.city},
              ${technician.zip}`;
    }
  } else {
    inspectionLocation = "";
  }

  const data = {
    categories: formatCategories(inspection.categories),
    overviewPhotosSrc: inspection.overview_photos,
    vin: inspection.vin,
    attributes: src_car_info.attributes,
    recalls: src_car_info.recalls,
    equipments: src_car_info.equipments,
    market_values: inspection.market_values
      ? inspection.market_values.prices
      : null,
    pickedVehicleDetails: inspection.vehicle_details,
    location: inspectionLocation,
    updated_at: updated_at,
    vehicle: inspection.market_values ? inspection.market_values.vehicle : null,
    final_notes: inspection.complete_note ? inspection.complete_note : null,
  };

  return data;
};
