import React, { useEffect, useState } from "react";
import "./index.css";
import InfoBlock from "./InfoBlock";
import MobileInfoBlock from "./MobileInfoBlock";
import MobilePicturesBlock from "./MobilePicturesBlock";
import MobileTitleBlock from "./MobileTitleBlock";
import PicturesBlock from "./PicturesBlock";

function MainInfo({ data, id }) {
  const {
    attributes,
    vin,
    vehicle,
    pickedVehicleDetails,
    location,
    updated_at,
    overviewPhotosSrc,
    market_values,
    categories,
  } = data;

  const [photosFromSteps, setPhotosFromSteps] = useState([]);

  useEffect(() => {
    if (categories) {
      let photos = [];
      categories.map(category => {
        category.subcategories.map(subcategory => {
          subcategory.steps.map(step => {
            photos = [...photos, ...step.photos];
          })
        })
      })

      setPhotosFromSteps(photos);
    }
  }, [categories])

  const vehicleCustomer = `${attributes.year} ${attributes.make} 
  ${attributes.model}`;

  let manualDamage = null;

  categories.map((category) => {
    if (category.name === "REQUIRED") {
      category.subcategories[0].steps.map((step) => {
        if (step.name === "MANUAL") {
          manualDamage = step.damage;
        }
      });
    }
  });

  return (
    <div className="main-container">
      <h2 className="main-container__vehicle">{vehicleCustomer}</h2>

      <div className="main-block">
        <InfoBlock
          attributes={attributes}
          vin={vin}
          location={location}
          pickedVehicleDetails={pickedVehicleDetails}
          vehicle={vehicle}
          time={updated_at}
          marketValues={market_values}
          manualDamage={manualDamage}
        />
        <PicturesBlock overviewPhotosSrc={overviewPhotosSrc} photosFromSteps={photosFromSteps}/>

        <MobileTitleBlock id={id} vehicle={vehicleCustomer} vin={vin}/>
        <MobilePicturesBlock overviewPhotosSrc={overviewPhotosSrc} photosFromSteps={photosFromSteps}/>
        <MobileInfoBlock
          attributes={attributes}
          vin={vin}
          location={location}
          pickedVehicleDetails={pickedVehicleDetails}
          vehicle={vehicle}
          time={updated_at}
          marketValues={market_values}
          manualDamage={manualDamage}
        />
      </div>
    </div>
  );
}
export default MainInfo;
