import React from "react";
import {Page, Text, View, Document, Image} from "@react-pdf/renderer";
import styles from "./styles";
import Header from "../Header";
import SideInfoBlock from "../SideInfoBlock";
import CarInfoBlock from "../CarInfoBlock";
import HighValueOptions from "../HighValueOptions";
import DemoListPNG from "../DemoListPNG";
import StupidBlock from "../StupidBlock";
import DetailedBlock from "../DetailedBlock";

const InspectionInfoDocument = ({data, categories, overviewPhotos}) => {
    const arrayOfIncludes = Array(9).fill(undefined);

    if (data.inspection) {
        categories.forEach(category => {
            switch (category.name) {
                case "Exterior":
                    arrayOfIncludes[0] = true;
                    break;
                case "Interior":
                    arrayOfIncludes[1] = true;
                    break;
                case "Frame":
                    arrayOfIncludes[2] = true;
                    break;
                case "Mechanical":
                    arrayOfIncludes[3] = true;
                    break;
                case "Electric":
                    arrayOfIncludes[4] = true;
                    break;
                case "Tires":
                    arrayOfIncludes[5] = true;
                    break;
                case "Test drive":
                    arrayOfIncludes[6] = true;
                    break;
                case "Video":
                    arrayOfIncludes[7] = true;
                    break;
                case "History":
                    arrayOfIncludes[8] = true;
                    break;
                default:
                    break;
            }
        });
    }

    const titlePhoto =
        overviewPhotos && overviewPhotos.length > 0 ? overviewPhotos[0] : null;
    return (
        <Document
            title={
                data.customer && data.customer.user
                    ? `${data.car_info.model} Inspection: ${
                        data.customer.user.last_name
                    } ${data.application_date.split(" ")[0]}`
                    : "Title"
            }
        >
            <Page size={[1440, 2692]} style={styles.page}>
                <Header/>
                <View style={styles.topContent}>
                    <SideInfoBlock data={data}/>
                    <View style={styles.imageBlock}>
                        <Image
                            source={`data:image/jpeg;base64,${titlePhoto}`}
                            style={styles.carImage}
                        />
                        <View>
                            <Image
                                source={require("../../assets/grade_ellipse.png")}
                                style={styles.gradeImage}
                            />
                            <Text style={styles.autograde}>AUTOGRADE</Text>

                            <Text style={styles.grade}>3.6</Text>
                            <Text style={styles.rough}>Rough</Text>
                        </View>
                    </View>
                </View>
                <View
                    style={{
                        width: "80%",
                        margin: "auto",
                        marginBottom: 60,
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap"
                    }}
                >
                    {overviewPhotos.map((p, index) => {
                        if (index === 0) {
                            return null;
                        }
                        return (
                            <Image
                                key={index}
                                source={`data:image/jpeg;base64,${p}`}
                                style={{
                                    height: 250,
                                    margin: 10
                                }}
                            />
                        );
                    })}
                </View>
                <Text style={styles.general}>GENERAL CAR INFO</Text>
                <View style={styles.central}>
                    <View style={styles.centralTextBlock}>
                        <Text style={styles.car_model}>
                            {data.car_info
                                ? `${data.car_info.model} ${data.car_info.vehicle_type} ${data.car_info.plate_number}`
                                : "No info"}
                        </Text>
                        <Text style={styles.vin}>{data.vin}</Text>
                        <Text style={styles.vinText}>VIN CODE</Text>
                    </View>
                    <CarInfoBlock data={data}/>
                    <HighValueOptions/>
                </View>
                <Text style={styles.inspectionIncludes}>Inspection includes</Text>
                <DemoListPNG data={arrayOfIncludes}/>
                <Text style={styles.result}>INSPECTION RESULT</Text>
                <View>
                    {data.inspection ? (
                        categories.map((e, index) => <StupidBlock data={e} key={index}/>)
                    ) : (
                        <View/>
                    )}
                </View>
                <DetailedBlock categories={categories}/>
            </Page>
        </Document>
    );
};

export default InspectionInfoDocument;
 