import React, { useState } from "react";
import "./index.css";

function ButtonToTop() {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  
  window.addEventListener('scroll', toggleVisible);

  return <div className={`button-to-top ${visible ? "to-top__block" : "to-top__none"}`} onClick={scrollToTop}>
      <div className="to-top__arrow"></div>
      <span>TOP</span>
  </div>;
}
export default ButtonToTop;
