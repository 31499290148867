import React, { useState } from "react";
import "../index.css";
import CategoryTable from "../CategoryTable";
import CategoryTiresTable from "../CategoryTiresTable";
import SectionTitle from "../../../SectionTitle";
import Collapse from "@material-ui/core/Collapse";
import { _getCategoryCondition as CategoryCondition } from "./categoryCondition";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

function Category({ category, pictureSrc, data }) {
  const [collapse, setCollapse] = useState(false);

  return (
    <>
      <SectionTitle
        title={category.name}
        containerStyle={{ marginTop: 40 }}
        collapse={collapse}
        setCollapse={setCollapse}
        id={`${category.name}`}
      />
      <Collapse
        in={!collapse}
        timeout="auto"
        unmountOnExit
        className="category-collapse"
      >
        <div className="category">
          <div className="category-container">
            <div className="category-picture">
              <LazyLoadImage
                src={pictureSrc.src}
                alt={category.name}
                effect="opacity"
              />
            </div>

            <div className="category-info">
              <h2>{category.name} condition</h2>
              <p>
                <CategoryCondition category={category} data={data} />
              </p>
            </div>
          </div>

          {category.name !== "TIRES" && <CategoryTable category={category} />}

          {category.name == "TIRES" && (
            <CategoryTiresTable category={category} />
          )}
        </div>
      </Collapse>
    </>
  );
}
export default Category;
