import {StyleSheet} from "@react-pdf/renderer"

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 45,
    },
    logoEllipse: {
        height: 100,
        width: 204,
    },
    logoText: {
        bottom: 80,
        left: 50,
        fontSize: 38,
    },
    headerReport: {
        fontSize: 38,
    },
    headerLink: {
        fontSize: 25,
        color: '#5956FF',
        textDecoration: 'none',
    },
});

export default styles;
