import React from "react";
import Categories from "./Categories";
import FinalNotes from "./FinalNotes";
import "./index.css";
import Recalls from "./Recalls";
import ResultsBadges from "./ResultsBadges";

function InspectionResults({ data }) {
  return (
    <div className="results-container">
      <ResultsBadges data={data} />
      <Categories data={data} />
      <FinalNotes notes={data.final_notes} />
      <Recalls recalls={data.recalls || []} />
    </div>
  );
}
export default InspectionResults;
