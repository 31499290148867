import React from "react";
import Loader from "react-loader-spinner";
import {PDFDownloadLink} from "@react-pdf/renderer";

import InspectionInfoDocument from "./InspectionInfoDocument";

const PDFDownload = ({data, appointmentID, type, overviewPhotos}) => {
    const getFileName = () => {
        if (type === "customer") {
            return `${data.car_info.model}${data.customer.user.last_name}${
                data.application_date.split(" ")[0]
            }.pdf`;
        }
        return `Custom Inspection ${appointmentID}`;
    };

    return (
        <PDFDownloadLink
            document={
                <InspectionInfoDocument
                    overviewPhotos={overviewPhotos}
                    data={data}
                    categories={data.newCategories}
                />
            }
            style={{textDecoration: "none", color: "#5956FF"}}
            fileName={getFileName()}
        >
            {({blob, url, loading, error}) =>
                loading ? (
                    <div className="full-size">
                        <Loader
                            type="BallTriangle"
                            color="#9C9BD4"
                            height={30}
                            width={30}
                        />
                    </div>
                ) : (
                    <div style={{textAlign: "center"}}>
                        <p>Download PDF</p>
                    </div>
                )
            }
        </PDFDownloadLink>
    );
};

export default PDFDownload;
