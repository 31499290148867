import React, { useEffect, useRef, useState } from "react";
import ImageGallery from "react-image-gallery";
import { resizedPhotoUrl } from "../../../helpers";

import "./index.css";
import "react-image-gallery/styles/css/image-gallery.css";

const sectionsImagesTemplate = {
  exterior: null,
  interior: null,
  damage: null,
  all: null,
};

function PicturesBlock({ overviewPhotosSrc, photosFromSteps }) {
  const [selectedSection, setSelectedSection] = useState("exterior");

  const [photos, setPhotos] = useState(sectionsImagesTemplate);
  const [covers, setCovers] = useState(sectionsImagesTemplate);

  useEffect(() => {
    const exteriorPhotos = overviewPhotosSrc.slice(0, 9);
    const interiorPhotos = overviewPhotosSrc.slice(9);
    const allPhotos = [...overviewPhotosSrc, ...photosFromSteps];

    setPhotos({
      exterior: exteriorPhotos.map((p) => resizedPhotoUrl(p)),
      interior: interiorPhotos.map((p) => resizedPhotoUrl(p)),
      all: allPhotos.map((p) => resizedPhotoUrl(p)),
      damage: photosFromSteps.map((p) => resizedPhotoUrl(p)),
    });

    setCovers({
      exterior: exteriorPhotos[0],
      interior: interiorPhotos[0],
      all: allPhotos[0],
      damage: photosFromSteps[0],
    });
  }, [selectedSection, photosFromSteps, overviewPhotosSrc]);

  const sections = [
    { cover: covers.exterior, title: "exterior" },
    { cover: covers.interior, title: "interior" },
    { cover: covers.damage, title: "damage" },
    { cover: covers.all, title: "all" },
  ];

  const selectedPhotos = photos[selectedSection];

  return (
    <div className="main-block__pictures">
      <div className="main-block__picture-list">
        {sections.map(({ title, cover }) => (
          <SectionItem
            title={title}
            cover={cover}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            key={title}
          />
        ))}
      </div>
      {selectedPhotos?.length > 0 ? (
        <ImageGallery
          items={selectedPhotos?.map((u) => ({ original: u }))}
          useBrowserFullscreen={false}
          showThumbnails={false}
          infinite={false}
          showPlayButton={false}
          lazyLoad
          showFullscreenButton={selectedPhotos?.length > 0}
        />
      ) : (
        <div className="no-image">No images</div>
      )}
    </div>
  );
}

function SectionItem({ title, cover, selectedSection, setSelectedSection }) {
  const ref = useRef();
  const width = ref.current?.offsetWidth * 2 || 200;

  return (
    <div className="picture-item" onClick={() => setSelectedSection(title)}>
      <div className="picture-item__image" ref={ref}>
        {cover ? (
          <img src={resizedPhotoUrl(cover, width)} alt="cover" />
        ) : (
          <span className="picture-item__no-image">
            There is no image with damage
          </span>
        )}
      </div>
      <div
        className={`picture-item__title ${
          selectedSection === title && "title-selected"
        }`}
      >
        {title}
      </div>
    </div>
  );
}

export default PicturesBlock;
