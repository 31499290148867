import {StyleSheet} from "@react-pdf/renderer";

const styles = StyleSheet.create({
    page: {
        color: "#3D3D3D",
        flex: 1
    },
    general: {
        fontSize: 38,
        bottom: 40,
        left: 50
    },
    central: {
        flexDirection: "row",
        justifyContent: "space-around"
    },
    centralTextBlock: {
        flexDirection: "column",
        maxWidth: 400,
    },
    car_model: {
        fontSize: 24
    },
    vin: {
        color: "#5956FF",
        fontSize: 15
    },
    vinText: {
        color: "#9C9BD4",
        fontSize: 15
    },
    topContent: {
        flexDirection: "row",
        justifyContent: "space-around"
    },
    imageBlock: {
        alignSelf: "center"
    },
    carImage: {
        width: 674,
        height: 486
    },
    gradeImage: {
        width: 118,
        height: 118,
        borderRadius: 50,
        bottom: 20,
        right: 70
    },
    autograde: {
        fontSize: 7,
        bottom: 115,
        right: 35
    },
    grade: {
        fontSize: 24,
        bottom: 110,
        right: 30,
        color: "#5956FF"
    },
    rough: {
        fontSize: 7,
        bottom: 100,
        right: 20
    },
    inspectionIncludes: {
        fontSize: 24,
        margin: 20,
        left: 40
    },
    result: {
        fontSize: 38,
        textAlign: "center",
        marginVertical: 30
    }
});

export default styles;
